<script lang="ts">
  import { tsToHoursDays } from "@common/tsToHoursDays";
  import Clock from "./icons/Clock.svelte";
  import TwemojiPartyPopper from "./icons/emoji/TwemojiPartyPopper.svelte";
  export let targetDate: Date;
  export let fullWidth: boolean;
  function pad(num: number) {
    return Math.abs(num).toString().padStart(2, "0");
  }
  $: time = targetDate.getTime();
  $: remaining = time - Date.now();
  $: ({ hours, days, minutes, seconds } = tsToHoursDays(remaining));
  $: setInterval(() => {
    remaining = time - Date.now();
  }, 1000);
  const deadline = 5 * 60_000; // 5 minute
  console.log({ remaining, deadline });
</script>

{#if !fullWidth}
  <div class="font-gameBoy flex gap-2 items-center" id="countdown">
    {#if remaining < -10}
      <TwemojiPartyPopper width="1.5em" height="1.5em" />
    {:else}
      <Clock
        width="1.5em"
        height="1.5em"
        class={remaining < deadline ? "animate-ping" : ""}
      />
    {/if}
    {#if days > 0}
      {days} D {pad(hours)}:{pad(minutes)}:{pad(seconds)}
    {:else}
      {pad(hours)}:{pad(minutes)}:{pad(seconds)}
    {/if}
  </div>
{:else if days > 1}
  {days} Días {pad(hours)} Horas {pad(minutes)} minutos y {pad(seconds)} segundos
{:else if days == 1}
  {days} Día {pad(hours)} Horas {pad(minutes)} minutos y {pad(seconds)} segundos
{:else}
  {pad(hours)} Horas {pad(minutes)} minutos y {pad(seconds)} segundos
{/if}

<style>
  div {
    font-size: 0.8em;
    text-shadow: var(--mario-shadow);
  }
</style>
